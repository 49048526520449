import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'Home',
    },
  },
  {
    name: 'Index',
    path: '/index',
    component: () => import('./view/Index'),
  },
  {
    name: 'Home',
    path: '/home',
    component: () => import('./view/Home'),
  },
  {
    name: 'Activity',
    path: '/activity',
    component: () => import('./view/Activity'),
  },
  {
    name: 'Webview',
    path: '/webview',
    component: () => import('./view/Webview.vue'),
  },
  {
    name: 'SignIn',
    path: '/signIn',
    component: () => import('./view/SignIn.vue'),
  },
  {
    name: 'Level',
    path: '/level',
    component: () => import('./view/Level'),
  },
  {
    name: 'BankList',
    path: '/bankList',
    component: () => import('./view/Bank/BankList.vue'),
  },
  {
    name: 'BindBank',
    path: '/bindBank',
    component: () => import('./view/Bank/BindBank.vue'),
  },
  {
    name: 'AccountDetails',
    path: '/accountDetails',
    component: () => import('./view/AccountDetails'),
  },
  {
    name: 'AboutUs',
    path: '/aboutUs',
    component: () => import('./view/AboutUs'),
  },
  {
    name: 'LoginPwd',
    path: '/loginPwd',
    component: () => import('./view/ChangePwd/LoginPwd'),
  },
  {
    name: 'WithdrawalPwd',
    path: '/withdrawalPwd',
    component: () => import('./view/ChangePwd/WithdrawalPwd'),
  },
  {
    name: 'ChangePwd',
    path: '/changePwd',
    component: () => import('./view/ChangePwd/ChangePassword'),
  },
  {
    name: 'ContractUs',
    path: '/contractUs',
    component: () => import('./view/ContactUs'),
  },
  {
    name: 'Booking',
    path: '/booking',
    component: () => import('./view/Booking/Index'),
  },
  {
    name: 'Deposit',
    path: '/deposit',
    component: () => import('./view/Deposit'),
  },
  {
    name: 'Withdraw',
    path: '/withdraw',
    component: () => import('./view/Withdrawal'),
  },
  {
    name: 'BookingRecord',
    path: '/booking/record',
    component: () => import('./view/Booking/Record'),
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
