import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import "vant/lib/index.css";
import { setToastDefaultOptions } from 'vant';
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createI18n } from 'vue-i18n'
import VueLuckyCanvas from '@lucky-canvas/vue'

setToastDefaultOptions({ forbidClick: true });

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app = createApp(App);

const user = JSON.parse(window.localStorage.getItem('user'))
const i18n = createI18n({
  locale: user?.lang || 'en_US',
  fallbackLocale: 'thi_IN',
  messages: {
    en_US: require('./locales/en_US.json'),
    thi_IN: require('./locales/thi_IN.json')
  }
})

app.use(VueLuckyCanvas)
app.use(i18n);
app.use(pinia);
app.use(router);
app.mount('#app');
