import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "app"
};
export function render(_ctx, _cache) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_van_config_provider = _resolveComponent("van-config-provider");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_config_provider, null, {
    default: _withCtx(() => [_createVNode(_component_router_view)]),
    _: 1
  })]);
}